import React, { useCallback, useEffect, useState } from "react";

import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineMinus } from "react-icons/ai";

import { BsPlus } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { useDispatch } from "react-redux";
import SectionTitle from "../components/SectionTitle";
import ProductImageCarasol from "../components/ProductImageCarasol";
import { toast } from "react-toastify";
import { Parser } from "html-to-react";
const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const htmlParser = new Parser();

  const [cartQuantity, setCartQuantity] = useState(1);
  const [singleProduct, setSingleProduct] = useState({});
  const [imageThamb, setImageThamb] = useState([]);

  console.log(singleProduct);

  const navigate = useNavigate();
  useEffect(() => {
    const singleProduct = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-products/${id}`
      );
      return setSingleProduct(res.data);
    };
    singleProduct();
  }, [id]);

  useEffect(() => {
    const imageThambs = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_URL}/api-thumb/${id}}`
      );

      return setImageThamb(res.data);
    };
    imageThambs();
  }, [id]);

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity(
      cartQuantity >= singleProduct?.product?.current_stock
        ? singleProduct?.product?.current_stock
        : (prev) => prev + 1
    );
    singleProduct?.product?.current_stock > cartQuantity &&
      toast.warn("Quantity Increased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity, singleProduct?.product?.current_stock]);
  const handleAddToCart = () => {
    dispatch(
      addToSingleCart({
        ...singleProduct.product,
        cartQuantity,
        variantPrice:
          singleProduct?.product?.discount > 0
            ? singleProduct?.product?.after_discount
            : singleProduct?.product?.unit_price,
      })
    );
  };
  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(
      addToSingleCart({
        ...singleProduct.product,
        cartQuantity,
        colorAtr: null,
        sizeAtr: null,
        variantPrice:
          singleProduct?.product?.discount > 0
            ? singleProduct?.product?.after_discount
            : singleProduct?.product?.unit_price,
      })
    );
    navigate("/checkout");
  };
  return (
    <>
      {singleProduct?.product && (
        <div className="container mx-auto md:my-10 min-h-screen">
          <div className="flex flex-col lg:flex-row md:px-10 gap-[2rem] lg:gap-[3rem] p-3 items-center lg:items-start">
            {/* Left site */}
            {/* <ProductDetailsCarousel
        key={singleProduct.id}
        singleProduct={singleProduct}
      /> */}
            <ProductImageCarasol
              singleProduct={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
              imageThamb={imageThamb}
            />

            {/* Right site */}
            {singleProduct?.status && (
              <div className="right flex lg:w-[40rem] w-full flex-col justify-start gap-3">
                <div className="text-4xl text-[#5c320f] font-semibold">
                  {singleProduct?.product?.name}
                </div>
                <div className="details flex flex-col gap-8 text-md mt-[12px]">
                  <p className="description text-[1rem] text-[#212121]">
                    {singleProduct?.product?.short_description}
                  </p>
                  <div className="price flex gap-3 items-center ">
                    <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium  uppercase items-center">
                      price :
                    </span>
                    <span className="inline-block text-2xl text-[#D89307] font-semibold ">
                      <div className="font-medium text-2xl">
                        {singleProduct?.product &&
                        singleProduct?.product?.discount > 0 ? (
                          <>
                            <span>
                              ৳ {singleProduct?.product?.after_discount}
                              <span className="text-[0.8rem] text-[#5c320f] font-normal	">
                                /{singleProduct?.product?.unit}
                              </span>
                            </span>
                          </>
                        ) : (
                          <>
                            {singleProduct?.product && (
                              <span>
                                ৳
                                {singleProduct?.product?.unit_price *
                                  cartQuantity}{" "}
                                <span className="text-[0.8rem] text-[#5c320f] font-normal	">
                                  /{singleProduct?.product?.unit}
                                </span>
                              </span>
                            )}
                          </>
                        )}
                      </div>
                      {/* {currencyFormatter(singleProduct?.product?.unit_price)}
                      <span className="text-[0.8rem] text-[#5c320f] font-normal	">
                        /{singleProduct?.product?.unit}
                      </span> */}
                    </span>
                  </div>
                </div>
                {singleProduct?.product?.current_stock > 0 ? (
                  <>
                    <div className="flex gap-3">
                      <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium  uppercase items-center">
                        Quantity :
                      </span>
                      <div className="flex items-center gap-4 ">
                        <button
                          className=" bg-slate-500/30 rounded-full flex justify-center items-center font-semibold text-xs h-5 w-5 hover:bg-[#5c320f] hover:text-white duration-500"
                          onClick={() => handleDecrease(singleProduct?.product)}
                        >
                          <AiOutlineMinus />
                        </button>
                        <span>{cartQuantity}</span>
                        <button
                          className=" bg-slate-500/30 h-5 w-5  rounded-full flex justify-center items-center hover:bg-[#5c320f] hover:text-white duration-500"
                          onClick={() => handleIncrease(singleProduct?.product)}
                        >
                          <BsPlus />
                        </button>
                      </div>
                    </div>
                    <div className="total-price flex gap-3 items-center ">
                      <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium uppercase items-center">
                        Total Price :
                      </span>
                      <span className="inline-block text-2xl font-semibold text-[#D89307] ">
                        {singleProduct?.product &&
                        singleProduct?.product?.discount > 0 ? (
                          <>
                            <span>
                              ৳{" "}
                              {singleProduct?.product?.after_discount *
                                cartQuantity}
                            </span>
                          </>
                        ) : (
                          <>
                            {singleProduct?.product && (
                              <span>
                                ৳
                                {singleProduct?.product?.unit_price *
                                  cartQuantity}
                              </span>
                            )}
                          </>
                        )}
                        {/* {currencyFormatter(
                          singleProduct?.product?.unit_price * cartQuantity
                        )} */}
                      </span>
                    </div>
                    <div className="total-price flex gap-3 items-center ">
                      <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium uppercase items-center">
                        Total Stock :
                      </span>
                      <span className="inline-block text-2xl font-semibold text-[#D89307] ">
                        {singleProduct?.product?.current_stock}
                      </span>
                    </div>
                    <div className="flex mt-8 items-center font-semibold gap-5">
                      <button
                        onClick={handleAddToCart}
                        className="py-3 px-5 rounded-full bg-[#6c350b] text-slate-50  hover:bg-[#5c320f] duration-300    items-center justify-center gap-2"
                      >
                        Add to Cart
                      </button>

                      <button
                        onClick={handleBuyNow}
                        className="py-3 px-5 rounded-full  border border-[#6c350b] text-black  hover:text-slate-50 hover:bg-[#6c350b] duration-300 items-center justify-center gap-2"
                      >
                        Buy Now
                      </button>
                    </div>
                  </>
                ) : (
                  <p className="text-4xl mt-5 text-red-700">Out Of Stock</p>
                )}
              </div>
            )}
          </div>
          <div className="px-3 mb-20 md:mb-15  md:px-0">
            <SectionTitle title={"Description"} />
            <div className="mb-10">
              {htmlParser.parse(singleProduct?.product?.description)}
            </div>

            <RelatedProduct singleProduct={singleProduct} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductDetails;
