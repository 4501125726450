import { AiOutlineLoading } from "react-icons/ai";

const Loader = () => {
  return (
    <div className="flex flex-col w-full min-h-[calc(100vh-10rem)] items-center justify-center ">
      <AiOutlineLoading className="inline-block text-2xl animate-spin" />
    </div>
  );
};

export default Loader;
