import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="min-h-[calc(100vh-10rem)] w-full container mx-auto flex-col gap-3 text-red-600 flex justify-center items-center p-3 md:p-0">
      <h3 className="font-semibold text-7xl">Opps!!!!!</h3>
      <h2 className="text-7xl text-center font-semibold">Cart Are Empty 😕</h2>
      <Link
        to={"/"}
        className="mt-3 py-3 px-5 border border-red-400 rounded-full font-semibold hover:bg-red-400 hover:text-white duration-300"
      >
        Go To Home
      </Link>
    </div>
  );
};

export default NotFound;
