import React from "react";

const SectionTitle = ({ title }) => {
  return (
    <div className="my-10 w-full">
      <h2 className="title text-3xl w-full">{title}</h2>
    </div>
  );
};

export default SectionTitle;
