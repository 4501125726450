import Banner from "../components/Banner";
// import BannerToow from "../components/BannerToow";
import CategoryCard from "../components/CategoryCard";
// import ProductCard from "../components/ProductCard";

import BannerSlider from "./BannerSlider";
import BestSelling from "./BestSelling";
// import ReviewCardPage from "./ReviewCardPage";
import OfferBanner from "../components/OfferBanner";
import CategoryWiseProducts from "./CategoryWiseProducts";

// import BannerToow from "../components/BannerToow";

// const categoryProduct = [
//   {
//     id: 1,
//     review: "/review/1674448752.jpg",
//   },
//   {
//     id: 2,
//     review: "/review/1674448776.jpg",
//   },
//   {
//     id: 3,
//     review: "/review/1674448884.jpg",
//   },
//   {
//     id: 4,
//     review: "/review/1674449061.jpg",
//   },
//   {
//     id: 5,
//     review: "/review/1674449268.jpg",
//   },
//   {
//     id: 6,
//     review: "/review/1674899990.jpg",
//   },
//   {
//     id: 7,
//     review: "/review/1674901795.jpg",
//   },
//   {
//     id: 8,
//     review: "/review/1674907418.jfif",
//   },
//   {
//     id: 9,
//     review: "/review/1674907426.jfif",
//   },
//   {
//     id: 10,
//     review: "/review/1674907459.jfif",
//   },
//   {
//     id: 11,
//     review: "/review/1674968102.jfif",
//   },
//   {
//     id: 12,
//     review: "/review/1674449914.jpg",
//   },
// ];

const Home = () => {
  return (
    <div className=" p-4 lg:p-0">
      <BannerSlider />
      <CategoryCard />
       <OfferBanner /> 
      <BestSelling />
      {/* <BannerToow /> */}
      {/* <ProductCard /> */}
      <CategoryWiseProducts />
      <Banner />
      {/* <ReviewCardPage categoryProduct={categoryProduct} /> */}
    </div>
  );
};

export default Home;
