import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "../components/Card";
import ReviewCard from "../components/ReviewCard";

const SubCategoryPage = () => {
  const [subCat, setSubCat] = useState([]);
  const { id } = useParams();
  const [review, setReview] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-subcategories/${id}`
        );
        const data = await res.json();
        setSubCat(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [id]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-review/${id}`
        );
        const data = await res.json();
        setReview(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [id]);
  console.log(review);

  return (
    <div className="px-3 container mx-auto min-h-screen">
      {subCat && subCat.length > 0 && (
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 mt-10 gap-2 md:gap-5">
          {subCat?.map(
            (product) =>
              product.category_name !== "REVIEW" && (
                <div key={product.id}>
                  <div className="overflow-hidden">
                    <Card product={product} />
                  </div>
                </div>
              )
          )}
        </div>
      )}

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 mt-16 lg:mt-10 gap-2 md:gap-5">
        {review?.map(
          (product) =>
            +product.review_id === 82 && (
              <div key={product.id}>
                <div className="overflow-hidden">
                  <ReviewCard product={product} />
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default SubCategoryPage;
