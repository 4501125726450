import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

//data fetching

export const reviewFetching = createAsyncThunk(
  "review/reviewFetching",
  async (id) => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api-review`);
    return res.data;
  }
);

export const reviewSlice = createSlice({
  name: "review",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(reviewFetching.pending, (state, action) => {
      state.status = "loading...";
    });
    builder.addCase(reviewFetching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(reviewFetching.rejected, (state, action) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default reviewSlice.reducer;
