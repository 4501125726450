import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Parser } from "html-to-react";
import Loader from "../../components/Loader";
import { singleFooterFetching } from "../../features/singleFooterPageSlice";
import SectionTitle from "../../components/SectionTitle";

const Pages = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const htmlParser = new Parser();

  const { items: singleFooterPage, loading } = useSelector(
    (state) => state.singleFooterPage
  );

  console.log(singleFooterPage);

  useEffect(() => {
    dispatch(singleFooterFetching(id));
  }, [dispatch, id]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="container mx-auto min-h-[calc(100vh-17rem)] md:px-0 px-4 py-10 ">
          {singleFooterPage?.map((data) => (
            <div key={data?.id}>
              <SectionTitle title={data?.title} />
              <p className="mt-10 ">{htmlParser.parse(data?.descriptions)}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Pages;
