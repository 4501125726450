import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  items: [],
  loading: false,
};

//data fetching

export const singleLandingPageFetching = createAsyncThunk(
  "singleLandingPage/singleLandingPageFetching",
  async (landingId) => {
    const res = await axios.get(
      `${process.env.REACT_APP_URL}/landing-page/${landingId}`
    );
    return res.data;
  }
);

export const singleLandingPageSlice = createSlice({
  name: "singleLandingPage",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(singleLandingPageFetching.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(singleLandingPageFetching.fulfilled, (state, action) => {
      state.status = false;
      state.items = { ...action.payload, cartQuantity: 1 };
    });
    builder.addCase(singleLandingPageFetching.rejected, (state, action) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default singleLandingPageSlice.reducer;
