import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userAndToken: sessionStorage.getItem("userAndToken")
    ? JSON.parse(sessionStorage.getItem("userAndToken"))
    : null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.userAndToken = action.payload;

      //set data in session storage
      sessionStorage.setItem(
        "userAndToken",
        JSON.stringify(state.userAndToken)
      );
    },
    logout: (state, action) => {
      state.userAndToken = null;
    },
    update: (state, action) => {
      state.userAndToken.user = action.payload;

      //set data in session storage
      sessionStorage.setItem(
        "userAndToken",
        JSON.stringify(state.userAndToken.user)
      );
    },
  },
});

export const { login, logout, update } = authSlice.actions;
export default authSlice.reducer;
