import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "../components/Card";

const CategoryWiseProducts = () => {
  const { items: products } = useSelector((state) => state.products);
  const { items: category } = useSelector((state) => state.category);

  const [categoryProduct, setCategoryProduct] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState({});

  useEffect(() => {
    setCategoryProduct(
      category?.map((category) => ({
        ...category,
        product: products?.filter(
          (product) => +product.category_id === +category.id
        ),
      }))
    );

    const initialVisibleProducts = {};
    category?.forEach((cat) => {
      initialVisibleProducts[cat.id] = 10;
    });
    setVisibleProducts(initialVisibleProducts);
  }, [category, products]);

  const handleSeeMore = (categoryId) => {
    setVisibleProducts((prevVisibleProducts) => ({
      ...prevVisibleProducts,
      [categoryId]: prevVisibleProducts[categoryId] + 10,
    }));
  };

  return (
    <section className="product-section container mx-auto px-3 mb-10">
      {categoryProduct?.map(
        (category) =>
          +category?.featured === 1 && (
            <div className="flex flex-col items-center" key={category.id}>
              <div className="header-section my-10 flex w-full items-start">
                <h2 className="title text-3xl">{category?.name}</h2>
              </div>
              <div className="flex gap-5 flex-wrap justify-center items-center">
                {category?.product
                  ?.slice(0, visibleProducts[category.id])
                  .map((product) => (
                    <Card key={product?.id} product={product} />
                  ))}
              </div>
              {category?.product?.length === 0 && (
                <p className="text-center col-span-full">No Products Found</p>
              )}
              {category?.product?.length > visibleProducts[category.id] && (
                <button
                  onClick={() => handleSeeMore(category.id)}
                  className="px-3 py-3 bg-[#49280c] rounded-md text-[#fff] text-center  font-semibold md:text-[1.2rem] text-lg mt-5"
                >
                  See more
                </button>
              )}
            </div>
          )
      )}
    </section>
  );
};

export default CategoryWiseProducts;
