import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

export const offerBannerFatching = createAsyncThunk(
  "offerBanner/offerBannerFatching",
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api-offerbanner`);

    return res.data;
  }
);

export const offerBannerSlice = createSlice({
  name: "offerBanner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(offerBannerFatching.pending, (state, action) => {
      state.status = "Loading";
    });

    builder.addCase(offerBannerFatching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });

    builder.addCase(offerBannerFatching.rejected, (state, action) => {
      state.status = "Some thing went wrong";
    });
  },
});

export default offerBannerSlice.reducer;
