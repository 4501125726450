import { useSelector } from "react-redux";

const OfferBanner = () => {
  const { items: offerBanner } = useSelector((state) => state.offerBanner);

  console.log(offerBanner);

  return (
    <>
      {offerBanner?.status === 1 && (
        <div className="container mx-auto mt-5">
          <div className=" banner-image rounded-md w-full  md:h-[21rem] h-[12rem] 2xl:h-[30rem] overflow-hidden">
            <img
              src={`${process.env.REACT_APP_URL}/uploads/offerbanner/${offerBanner.offerbanner}`}
              className="w-full h-full"
              alt="banner"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default OfferBanner;
