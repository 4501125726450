import { useState } from "react";
import { useSelector } from "react-redux";

import Card from "../components/Card";

const BestSelling = () => {
  const { items: data } = useSelector((state) => state.bestSell);

  const [visibleProducts, setVisibleProducts] = useState(10);

  const handleSeeMore = () => {
    setVisibleProducts((prevVisibleProducts) => prevVisibleProducts + 10);
  };

  return (
    <>
      {data.length > 0 && (
        <div className="wrapper flex flex-col items-center">
          <div className=" px-3 header-section my-10 flex w-full items-start">
            <h2 className="title text-3xl">Best Selling</h2>
          </div>

          <div className="flex gap-5 flex-wrap justify-center items-center">
            {data.slice(0, visibleProducts).map((product) => (
              <Card key={product.id} product={product} />
            ))}
          </div>

          {data.length > visibleProducts && (
            <button
              onClick={handleSeeMore}
              className="px-3 py-3 bg-[#49280c] rounded-md text-[#fff] text-center font-semibold md:text-[1.2rem] text-lg mt-5"
            >
              See more
            </button>
          )}
        </div>
      )}
    </>
  );
};

export default BestSelling;
