import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import Card from "../components/Card";

const BestSellingPage = () => {
  const { items: data, loading } = useSelector((state) => state.bestSell);
  return (
    <div className=" px-3 container mx-auto my-10 ">
      {loading ? (
        <div className="flex items-center justify-center min-h-[calc(100vh-300px)]">
          <ClipLoader
            visible={true}
            height="120"
            width="120"
            color="#D89307"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : data?.length > 0 ? (
        <>
          <div className="grid  grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5  gap-5">
            {data?.map((product) => (
              <Card key={product.id} product={product} />
            ))}
          </div>
        </>
      ) : (
        <p className="text-center text-rose-500 text-xl">No Products Found</p>
      )}
    </div>
  );
};

export default BestSellingPage;
