import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  pages: [],
  status: null,
};

export const footerPagesFetching = createAsyncThunk(
  "footerPages/footerPagesFetching",
  async () => {
    const res = await axios.get(`${process.env.REACT_APP_URL}/api-pages`);
    return res.data;
  }
);

export const footerPagesSlice = createSlice({
  name: "footerPages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(footerPagesFetching.pending, (state) => {
      state.status = "loading..";
    });
    builder.addCase(footerPagesFetching.fulfilled, (state, action) => {
      state.status = "";
      state.pages = action.payload;
    });
    builder.addCase(footerPagesFetching.rejected, (state) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default footerPagesSlice.reducer;
